<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Movimientos</h4>
            <div class="small text-muted">Consultar movimientos contables.</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="12">
            <b-row :key="keyForceUpdate">
                <b-col sm="2">
                  <FindObject render="search"
                              type="cash" 
                              @select-object="loadCash($event)" 
                              :valueID="filters.finances_cash_id"/>
                </b-col>            
                <b-col md="2">
                  <b-form-group label="Desde">    
                    <b-form-input type="date" size="sm" v-model="filters.date_start" placeholder="Fecha Desde"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="2">                  
                  <b-form-group label="Hasta">    
                    <b-form-input type="date" size="sm" v-model="filters.date_end" placeholder="Fecha Hasta"></b-form-input>
                  </b-form-group>
                </b-col>                      
                <b-col sm="2">
                  <FindObject render="search"
                              type="currency" 
                              @select-object="loadCurrency($event)" 
                              :valueID="filters.currency_id"/>
                </b-col> 
                
                <b-col sm="2" class="mt-4">
                  <b-button variant="outline-dark" size="sm" @click="filterReport()">
                    <b-icon icon="filter" size="sm" class="mr-1" style="font-size:15px;"></b-icon>              
                    Filtrar
                  </b-button>
                </b-col>                                     
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="true"                    
                    :fields="table.fields"
                    :filter="table.filter"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    :items="table.items"
                    v-if="table.items.length">

              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"                    
                  :key="field.key"
                  :style="{ width: field.width }"
                >
              </template> 

              <template v-slot:cell(date)="data">
                <div v-if="data.item.date_time">
                  {{ moment(data.item.date_time).format('DD MMMM YYYY') }}
                </div>
              </template>

              <template v-slot:cell(time)="data">
                <div v-if="data.item.date_time">
                  {{ moment(data.item.date_time).format('HH:MM') }}
                </div>
              </template>

              <template v-slot:cell(reference)="data">
                {{data.item.reference}}
              </template>

              <template v-slot:cell(amount)="data">
                <span v-if="data.item.type_balance=='+'" class="cash-detail-text-success">
                  {{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency.code}).format(data.item.amount)}}
                </span>
                <span v-if="data.item.type_balance=='-'" class="cash-detail-text-danger">
                  ({{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency.code}).format(data.item.amount)}})
                </span>
              </template>

              <template v-slot:cell(f_action)="data">
                <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right" v-if="data.item.print">
                  <b-dropdown-header>Acciones</b-dropdown-header>
                </b-dropdown>
              </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
        <b-row v-if="table.items.length">
          <b-col sm="12">
            <hr>
          </b-col>
        </b-row>
        <b-row align-h="end" v-if="table.items.length">
          <b-col sm="4">            
            <b-table-simple hover small responsive bordered>             
              <b-thead head-variant="dark">                                          
                <b-tr>                      
                  <b-th width="30%" class="text-right">
                    <div class="mr-1">Moneda</div>                        
                  </b-th>
                  <b-th width="35%" class="text-right">
                    <div class="mr-1">Filtro</div>                        
                  </b-th>
                  <b-th width="35%" class="text-right">
                    <div class="mr-1">Total</div>
                  </b-th>                                
                </b-tr>
              </b-thead>  
              <b-tbody> 
                <b-tr v-for="(element, index) in this.getTotalByCurrency()" :key="index">                                       
                  <b-td class="text-right align-middle">        
                    <div class="mr-1">
                      <b>{{element.currency_name}}</b>
                    </div>            
                  </b-td>                  
                  <b-td class="text-right align-middle">        
                    <div class="mr-1 cash-detail-amount-totals">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:element.currency_code}).format( element.currency_amount )}}
                    </div>            
                  </b-td>
                  <b-td class="text-right align-middle cash-detail-amount-totals">   
                    <div class="mr-1" v-if="filters.finances_cash">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency:element.currency_code}).format( getTotalCash(element.currency_id) )}}
                    </div>            
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>            
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
      
          <b-col>
            <nav v-if="table.tablePaginate">
              <b-pagination class="pull-right mb-0"
                              size="sm"
                              pills=""
                              :total-rows="table.tablePaginate.total"
                              :per-page="table.tablePaginate.per_page"
                              v-model="table.currentPage"
                              @input="filterReport()" />
            </nav>
          </b-col>  
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment'
  import Storage from '@/handler/storageLocal'
  import Vue from 'vue'
  import FindObject from '@/components/inc/find/findObject' 

  export default {
    components: {
      FindObject,
    }, 
    data: () => {
      return {      
        access: {
          module_id: Modules.FINANZAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'cashDetail',
          elements: {}
        }, 
        table : {
          items: [],
          filters: null,
          fields: [            
            {key: 'date', label: 'Fecha', class: "align-middle", width:"10%"},
            {key: 'time', label: 'Hora', class: "align-middle", width:"10%"},
            {key: 'reference', label: 'Referencia', class: "align-middle", width:"50%"},
            {key: 'amount', label: 'Importe', class: "align-middle text-right", width:"30%"},
          ],          
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          tablePaginate: null,          
        },
        filters: {
          finances_cash: null,
          finances_cash_id: 0,
          currency: null,
          currency_id: 0,
          date_start: '',
          date_end: '',
        },  
        keyForceUpdate: 0,   
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.searchCash()            
    },
    methods: {
      searchCash() {                
        var result = serviceAPI.obtenerCajas()

        result.then((response) => {
          var data = response.data

          if(data.length) {     
            this.filters.finances_cash = data[0]
            this.filters.finances_cash_id = data[0].id
          }    
          this.restoreFilterStorage() 
          this.filterLoad()      

          this.forceUpdate()                    
          this.filterReport()          
        })                  
      },      
      filterLoad() { 
        this.filters.date_start = moment().format('YYYY-MM-DD')  
        this.filters.date_end = moment().format('YYYY-MM-DD') 
      },
      loadCash (object) {        
        if(object){
          this.filters.finances_cash = object
          this.filters.finances_cash_id = object.id        
        } else {
          this.filters.finances_cash = null
          this.filters.finances_cash_id = 0
        }
      }, 
      loadCurrency (object) {        
        if(object){
          this.filters.currency = object
          this.filters.currency_id = object.id        
        } else {
          this.filters.currency = null
          this.filters.currency_id = 0
        }
      }, 
      filterReport(){
        this.saveFilterStorage()     

        if(!this.filters.finances_cash_id) {
          this.$awn.alert("Debe seleccionar una caja");
          return false;
        }

        let loader = this.$loading.show()   
            
        var result = serviceAPI.filtrarCajaDetalle(this.filters, this.table.currentPage)        

        result.then((response) => {
          loader.hide()          
          this.table.items = response.data.data
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });
      },

      getTotalByCurrency() {        
        var arrTotal = []

        if(this.table.items.length) {
          this.table.items.forEach(element=>{
            var code = element.currency.id     
            arrTotal[code] = null
            
            arrTotal[code] = {
              currency_id: element.currency.id,
              currency_name: element.currency.name,
              currency_code: element.currency.code,
              currency_amount: 0,
            }
          })          
        }
        
        this.table.items.forEach(element=>{          
          var code = element.currency.id          
          arrTotal[code].currency_amount = parseFloat(arrTotal[code].currency_amount) + parseFloat(element.amount)
        })

        arrTotal = arrTotal.filter((obj) => { return ![null, undefined].includes(obj) })

        return arrTotal        
      },
      getTotalCash($currency_id) {
        var total = 0
        if(this.filters.finances_cash) {
          this.filters.finances_cash.total_available.forEach(element => {
            if(element.currency_id == $currency_id) {
              total = element.amount
            }            
          }); 
        }
        return total
      },

      forceUpdate() {
        this.keyForceUpdate = this.keyForceUpdate + 1
      },          

      // FILTROS SAVE
      saveFilterStorage() {
        Storage.setValue('filter_cash_details', JSON.stringify(this.filters))
      },
      restoreFilterStorage() {
        if(Storage.getValue('filter_cash_details')) {
          this.filters = JSON.parse(Storage.getValue('filter_cash_details'))
        }         
      },         
    }    
  }
</script>
<style scoped>
  .cash-detail-amount-totals {
    font-size: 15px;
    color:darkgreen;
    font-weight: 800;
  }
  .cash-detail-text-success {
    color:green;    
  }
  .cash-detail-text-danger {
    color:red;
  }
</style>